import Accordion from "../../../../components/organisms/accordion"
import React from "react"
import { NextSelect } from "../../../molecules/select/next-select"
import { ArrowLongDownIcon } from "@heroicons/react/24/outline"

export default function RetailDashboard() {
  return (
    <div className="grid grid-cols-4 gap-6">
      <div className="col-span-3">
        <div className=" shadow-sm p-6 bg-white rounded-lg">
          <div className="text-sm font-semibold border-b border-bottom border-gray-300 pb-4">
            Here's your bussiness result today
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-1 p-4">
              <div className="text-xs font-medium">Net Sales</div>
              <div className="text-xl">0</div>
            </div>
            <div className="col-span-1 p-4">
              <div className="text-xs font-medium">Total Orders</div>
              <div className="text-xl">0</div>
            </div>
          </div>
        </div>
        <div className=" shadow-sm p-6 bg-white rounded-lg mt-6">
          <div className="text-sm font-semibold border-b border-bottom border-gray-300 pb-4">
            Order Statuses
          </div>
          <div className="mt-4">
            <Accordion defaultValue={[""]} type="multiple">
              <Accordion.Item
                className="text-sm font-semibold"
                value={"WAITHING_FOR_PAYMENT"}
                title="Waiting for Payment"
              >
                <p className="inter-base-regular text-grey-50">
                  Waiting for Payment
                </p>
                <div className="mt-xlarge flex flex-col gap-y-xlarge"></div>
              </Accordion.Item>
              <Accordion.Item
                className="text-sm font-semibold"
                value={"WAITING_FOR_PAYMENT"}
                title="Waiting for Payment"
              >
                <p className="inter-base-regular text-grey-50">
                  Waiting for Deliver
                </p>
                <div className="mt-xlarge flex flex-col gap-y-xlarge"></div>
              </Accordion.Item>
              <Accordion.Item
                className="text-sm font-semibold"
                value={"WAITING_FOR_DELIVER"}
                title="On the way"
              >
                <p className="inter-base-regular text-grey-50">
                  Waiting for Deliver
                </p>
                <div className="mt-xlarge flex flex-col gap-y-xlarge"></div>
              </Accordion.Item>
              <Accordion.Item
                className="text-sm font-semibold"
                value={"CANCELD"}
                title="Canceled"
              >
                <p className="inter-base-regular text-grey-50">
                  Waiting for Deliver
                </p>
                <div className="mt-xlarge flex flex-col gap-y-xlarge"></div>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="shadow-sm p-6 bg-white rounded-lg mt-6">
          <div className="text-sm font-semibold border-b border-bottom border-gray-300 pb-4">
            Activities
          </div>
          <div className=" mt-6">
            <Timeline />
          </div>
        </div>
      </div>
      <div className="col-span-1 p-6 bg-white rounded-lg flex flex-col gap-4">
        <NextSelect
          label="Time"
          onChange={() => {}}
          options={[]}
          value={""}
          placeholder="Select"
          isClearable
        />
        <NextSelect
          label="Sale channel"
          onChange={() => {}}
          options={[]}
          value={""}
          placeholder="Select"
          isClearable
        />
        <div className="flex justify-center">
          <ArrowLongDownIcon className="w-4 h-4" />
        </div>
        <div className="font-semibold">Statistic</div>
        <div className="p-3 border border-gray-300 rounded-lg flex justify-between text-xs ">
          <div className=" font-medium">Total orders</div>
          <div>0</div>
        </div>
        <div className="p-3 border border-gray-300 rounded-lg flex justify-between text-xs ">
          <div className=" font-medium">Total sales by product</div>
          <div>0</div>
        </div>
        <div className="p-3 border border-gray-300 rounded-lg flex justify-between text-xs ">
          <div className=" font-medium">Total products by units sold</div>
          <div>0</div>
        </div>

        <div className="border-t border-gray-300 py-3">
          <div className="text-xs font-medium">Top products sold</div>
          {[1, 2, 3].map((item) => {
            return (
              <div key={item} className="flex gap-3 my-2">
                <div className="w-20 h-20 bg-gray-300 rounded-md"></div>
                <div className="text-xs font-medium">
                  <p>Product Name</p>
                  <p className="mt-2">Solded: 1000</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Timeline = () => {
  return (
    <div>
      <ol className="relative border-l border-gray-200 dark:border-gray-700">
        <li className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            March 2022
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Lorem Ipsum is simply dummy text
          </h3>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
          <a
            href="#"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
          >
            Learn more{" "}
            <svg
              className="w-3 h-3 ml-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
        <li className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            March 2022
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Lorem Ipsum is simply dummy text
          </h3>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </li>
        <li className="ml-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            April 2022
          </time>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Lorem Ipsum is simply dummy text
          </h3>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </li>
      </ol>
    </div>
  )
}
